@use 'src/base_css/base';

.download_link:hover{
  text-decoration: underline;
  cursor: pointer;
}

.footer{
  font-family: "Roboto", Roboto, sans-serif;
  @media screen and (max-width: base.$breakpoint) {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;

    .contracts p{
      display: flex;
      justify-content: center;
      }
  }
  @media screen and (min-width: base.$breakpoint){
    font-size: 1em;
  }
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  margin-left: 10vh;
  margin-right: 10vh;
  justify-content: space-between;

  .contracts p{
    display: flex;
    justify-self: start;
    }
}

.footer div{
  padding-bottom: 1vh;
}

.contracts{
  display: flex;
  flex-direction: column;
}

.social_network img{
  height: 5vh;
  width: auto;
}

.contracts a{
  color: base.$baseColor;
  text-decoration: none;
}

.contracts p{
  margin: 0;
  padding: 0;
}

.black_part {
  display: flex;
  justify-content: center;
  background: #1F2426;
  color: white;
  font-family: "Roboto", Roboto, sans-serif;
  padding: 3vh 1vh 3vh 1vh;
}

.black_part a {
  text-decoration: none;
  color: white;
}