@use 'src/base_css/base';

.topic {
    padding-top: calc(10vw + 2vh);
    font-size: 3em;
    color: base.$baseColor;
    margin: 0;

    @media screen and (max-width: 668px) {
        padding-top: calc(9vw + 6vh);
    }
}

.content {
    padding: 2vh 8vw 0 8vw;
    font-family: "Roboto", Roboto, sans-serif;
}

// TODO vh40?
.galery_img {
    height: 35vh;
    width: auto;
}

#reference {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

#reference img {
    margin: 3px 3px 0 3px;
}

@media screen and (max-width: base.$breakpoint) {
    .galery_img {
        height: 35vh;
        width: 95vw;
    }
}