@use 'src/base_css/base';

@media screen and (min-width: base.$breakpoint) {

  #mobileNav{
    display: none;
  }

  .nav_link{
    padding: 2vh;
  }

  .background_color {
    background-color: #FFFFFF;
    position: fixed;
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 999;
    height: 9rem;
  }

  #header {
    width: 95%;
    margin: 1vh 2.5%;
    height: 8rem;
    display: flex;
    flex-direction: row;
    //align-items: center;
    align-content: center;
  }

  #nav {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .nav_logo {
    position: absolute;
    height: 8rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 50%;
  }

  .logo_link {
    height: 9vw;
    padding: 0 !important;
  }

  .nav_list {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    //justify-content: space-between;
    padding: 0;
  }

  .nav_list a {
    text-decoration: none;
    color: base.$baseColor;
    font-family: "Roboto", Roboto, sans-serif;
    //font-size: clamp(0.5rem, 10vw, 2rem);
    font-size: 2vw;
    font-weight: bold;
  }

  .nav_list a:hover{
    text-decoration: underline;
  }

  .left_part, .right_part {
    border-top: 2px base.$baseColor solid;
    border-bottom: 2px base.$baseColor solid;
    display: flex;
    padding: 2vh 0;
    justify-content: center;
    width: 100%;
    align-self: center;
    height: 4rem;
  }

  .left_part a, .right_part a {
    width: 100%;
  }

  .left_part {
    padding-right: 10vw;
  }

  .right_part {
    padding-left: 6vw;
  }

  #burgerMenu{
    display: none;
  }
}

@media screen and (max-width: base.$breakpoint) {

  #nav{
    display: flex;
    flex-direction: row;
    padding: 1vh 4vw;
    justify-content: space-between;
    width: 100vw;
  }

  .left_part, .right_part{
    display: none;
  }

  .logo_link img{
    height: 9vh;
  }

  .background_color{
    position: fixed;
    z-index: 1;
    background: #ffffff;
    display: flex;
    //flex-direction: row;
    justify-content: space-between;
    width: 100vw;
  }

  #burgerMenu{
    display: flex;
    padding-right: 4vh;
    align-self: center;
  }

  .header{
    display: flex;
    flex-direction: column;
  }

  .mobile_navigation_links{
    position: fixed;
    margin-top: 10vh;
    background: #ffffff;
    z-index: 1;
    width: 100vw;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid base.$baseColor;
  }

  .mobile_navigation_links div{
    border-top: 2px solid white;
    padding: 2vh 4vh;
  }

  .mobile_navigation_links a{
    width: 100vw;
    font-size: 2em;
    color: base.$baseColor;
    text-decoration: none;
  }

  #burgerMenu img{
    padding-top: 1vh;
    height: 5vh;
  }
}