@use 'src/base_css/base';

.mainContainer {
  text-align: initial !important;

  padding-left: 5vw;
  padding-right: 5vw;

  padding-top: calc(9vw + 3.5vh);

  .mainTopic {
    text-align: center;
    font-size: 3em;
    color: base.$baseColor;
    margin-top: 0;
    @media screen and (max-width: base.$breakpoint) {
      padding-top: 4vh;
      margin-bottom: 0;
    }
  }

  .strong {
    list-style-type: none;
  }

  .contentWrapper {
    position: relative;

    display: flex;
    justify-content: space-between;
    margin: 30px;

    h2 {
      margin-left: 2vw;
      color: base.$baseColor;
      font-size: clamp(1em, 12px + 2vw, 3em);
    }

    .content {
      font-size: clamp(1em, 9px + 1vw, 2em);
      max-width: 40vw;

      @media screen and (max-width: 668px) {
        max-width: 80vw;
      }
    }


    @media screen and (max-width: 668px) {
      flex-direction: column;
      height: fit-content;
    }

    @media screen and (max-width: 668px) {
      height: fit-content;
    }
  }
}

.imageWrapper {

  .imgMobile {
    display: none;
    @media screen and (max-width: 900px) {
      position: initial;
      width: 55vw;
      height: auto;
      margin-inline: auto;
      display: block;
    }
  }

  .imgPC {
    max-width: 35vw;
    width: auto;
    height: auto;

    @media screen and (max-width: 900px) {
      position: initial;
      max-width: 55vw;
      margin-inline: auto;

      display: none;
    }
  }

  @media screen and (max-width: 668px) {
    text-align: center !important;
  }
}


.flexRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}