@use 'src/base_css/base';

.contact_input {
  display: flex;
  flex-direction: row;
  justify-items: center;
  @media screen and (max-width: base.$breakpoint) {
    justify-content: center;
    align-items: center;
  }
}

.contact_input img {
  width: 4vh;
  height: 4vh;
  padding-right: 1vh;
}

.contact_input a {
  //text-decoration: none;
  color: base.$baseColor;
}

@media screen and (min-width: base.$breakpoint) {

  .topic {
    padding-top: calc(9vw + 2vh);
    font-size: 3em;
    color: base.$baseColor;
    margin: 0;
  }

  .contact_section {
    padding: 5vh 10vh;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }

  .contact_section_inverse {
    background-color: #fdfcfc;
    border-radius: 10px;
    margin: 0 5vh;
    padding: 5vh 5vh;
    display: flex;
    flex-direction: row;
  }

  .inverse_map {
    margin-right: 12vw;
  }

  .contact_section_without_map {
    padding-top: 5vh;
    padding-left: 10vh;
    padding-right: 10vh;
    display: flex;
    //justify-content: left;
  }

  .left_part {
    font-size: 1.2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: base.$breakpoint) {
  .topic {
    padding-top: 10vh;
    font-size: 3em;
    color: base.$baseColor;
    margin: 0;
  }

  .left_part {
    padding-bottom: 5vh;
  }

  .contact_section_inverse {
    background-color: #fdfcfc;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column-reverse;
  }

  .map{
    width: 98%;
    margin: auto;
  }

  .inverse_map{
    width: 98%;
    margin: auto;
  }

}
