@use 'src/base_css/base';

.header3{
  font-size: 1.35em;
}

.section_header{
  margin-bottom: 4vh;
}

@media screen and (min-width: base.$breakpoint) {

  #video_part_phone{
    display: none;
  }

  .first_line {
    font-weight: bold;
    font-size: 5em;
  }

  .second_line {
    font-size: 3em;
  }

  .other_text {
    font-size: 2em;
    margin-bottom: 4vh;
  }

  .first_content {
    padding: 10rem 8vw 0 8vw;
    font-family: "Roboto", Roboto, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content {
    padding: 0 8vw;
    font-family: "Roboto", Roboto, sans-serif;
  }

  .button {
    font-family: "Roboto", Roboto, sans-serif;
    text-decoration: none;

    font-size: 2em;
    color: #FFFFFF;
    background-color: base.$baseColor;
    border: 2px base.$baseColor solid;
    border-radius: 25px;

    padding: 1vh 6vw;
    //width: 60%;
    display: flex;
    justify-content: center;

    align-items: center;
    text-align: center;

    cursor: pointer;
  }

  .link_button{
    background-color: base.$baseColor;
    border: 2px base.$baseColor solid;
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 1vh 0;
    //width: 30vw;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
  }

  .small_button{
    font-family: "Roboto", Roboto, sans-serif;
    text-decoration: none;
    font-size: 2em;
    color: #FFFFFF;
    background-color: base.$baseColor;
    padding: 1vh 6vw;
    border-radius: 25px;
    border: 2px base.$baseColor solid;
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .home_pictures {
    display: flex;
    padding: 0 5%;
    align-items: center;
    justify-content: center;
    max-height: 60vh;
  }

  .home_pictures img {
    //float: left;
    max-height: 60vh;
    max-width: 30vw;
    //object-fit: cover;
  }

  .home_section_button{
    display: flex;
    justify-content: center;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .header1 {
    font-size: 3em;
    color: base.$baseColor;
  }

  .info {
    font-size: 1.5em;
    padding-bottom: 4vh;
  }

  .info_section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 2.5%;

  }

  .grey_part {
    background-color: #F2F2F2;
    border-radius: 20px;
    padding: 2vh;
    width: 35vw;
    //margin-bottom: 2vh;
    text-align: center;
  }

  .grey_part p {
    font-size: 1.3em;
  }

  .about_us_part {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  #video_part iframe {
    width: 45vw;
  }

  .about_us_pictures {
    display: flex;
    margin: 0 2.5%;
    justify-content: space-around;
    align-items: center;
    height: 50vh;
    padding-bottom: 4vh;

  }

  .about_us_pictures img {
    height: 95%;
  }
}

@media screen and (max-width: base.$breakpoint) {
  .first_line {
    padding-top: 10vh;
    font-weight: bold;
    font-size: 4em;
  }

  .link_button{
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .second_line {
    font-size: 2em;
  }

  .other_text {
    font-size: 1.5em;
    margin-bottom: 4vh;
  }

  .content {
    padding: 0 8vw 0 8vw;
    font-family: "Roboto", Roboto, sans-serif;
  }

  .small_button{
    font-family: "Roboto", Roboto, sans-serif;
    font-size: 1.2em;
    color: #FFFFFF;
    background-color: base.$baseColor;
    padding: 2vh 6vw;
    border-radius: 25px;
    border: 2px base.$baseColor solid;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
  }

  .button {
    font-family: "Roboto", Roboto, sans-serif;
    font-size: 1.2em;
    color: #FFFFFF;
    background-color: base.$baseColor;
    padding: 2vh 6vw;
    border-radius: 25px;
    border: 2px base.$baseColor solid;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
  }

  .home_pictures {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4vh;

  }

  .home_pictures img {
    width: 100vw;
  }

  .header1 {
    font-size: 3em;
    color: base.$baseColor;
  }

  .info {
    font-size: 1.5em;
    padding-bottom: 4vh;
  }

  .grey_part {
    background-color: #F2F2F2;
    border-radius: 20px;
    padding: 2vh;
    width: 85vw;
    //margin-bottom: 2vh;
    text-align: left;
    margin-bottom: 2vh;
  }

  .grey_part p {
    font-size: 1.2em;
  }

  .about_us_part {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1vh;
  }

  #video_part_phone iframe {
    width: 94%;
    margin-top: 1vh;
  }

  #video_part_pc{
    display: none;
  }

  .about_us_pictures {
    display: flex;
    flex-direction: column;
    padding-bottom: 2vh;
  }

  .about_us_pictures img {
    width: 100vw;
  }
}


